<template>
  <div v-if="$auth.check()">
    <v-app-bar color="primary" dark app clipped-left>
      <v-app-bar-nav-icon v-if="is_mobile" @click.stop="clickNavIcon"></v-app-bar-nav-icon>
      <v-toolbar-title class="d-flex justify-space-between">
        <v-img src="@/assets/Cabbio 2.png" contain class="logo" max-width="120" min-width="120" min-height="51.3" max-height="51.3">  </v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div v-for="url, idx in country_flags" :key="idx">
        <v-img :src="url" contain class="logo mx-1" max-width="34" min-width="34" min-height="34" max-height="34"></v-img>
      </div>
      <!-- <div v-if="!is_mobile">
        <v-btn text v-for="(link,i) in links" :key="i" :to="link.to"></v-btn>
      </div> -->
    </v-app-bar>
  </div>
</template>
<script>
import { header } from "@/router/links";
import resize from "@/mixins/resize";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    SideBar: () => import("./_drawer_01")
  },
  mixins: [resize],
  data() {
    return {
      links: header,
      show_side_bar: false
    };
  },
  methods: {
    ...mapMutations({
      setDrawer: "App/setDrawer"
    }),
    onClick() {},
    clickNavIcon() {
      this.setDrawer(!this.$store.state.App.drawer);
    }
  },
  computed: {
    ...mapState({
      logo_left: state=>state.App.logo_left,
      country_flags: state=>state.App.country_flags,
    })
  },
};
</script>

<style scoped>
  .logo{
    width: 120px !important;
    height: auto;
  }
</style>
